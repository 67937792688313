// src/components/UserInfo.scss
.user-info {
    background-color: #1d1e24;
    color: #fff;
    padding: 0.1em 1em;
    border-radius: .8em;
    border: solid .1em #45485a;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
    margin-bottom: 1em;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    text-align: left;

    .user-email-info,
      .user-wallet-address-info {
        cursor: default;
        user-select: none;
      }

    p {
      margin: 0.5em 0;
      font-size: 1em;
      font-weight: 600;

      @media (max-width: 480px) {
        margin: 0.3em 0;
        font-size: 0.8em;
      }

    }
  
    @media (min-width: 481px) {
        max-width: 29em;
    }
  
    @media (max-width: 480px) {
      width: 100%;
      max-width: 77%;
    }
  }
  