/* src/App.scss */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

// Color Variables
$primary-bg: #101010;
$primary-bg-light: #eaf2ff;
$secondary-bg: #1d1e24;
$secondary-bg-light: #dedede;
$accent-color: #00c9ff;
$text-color: #fff;
$light-text-color: rgba(255, 255, 255, 0.3);
$error-color: #f44336;
$button-bg: #fff;
$button-hover-bg: linear-gradient(45deg, #344c64, #373a40);
$button-hover-color: $text-color;
$button-active-transform: translateY(0.1em);
$blur-bg: rgba(29, 30, 36, 0.1);
$box-shadow: rgba(0, 0, 0, 0.5);

// Font Variables
$font-family: "Roboto", sans-serif;
$font-size-default: 1em;
$font-size-large: 1.5em;
$font-size-xlarge: 2em;
$font-size-small: 0.9em;

// Mixin for button styles
@mixin button-styles {
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  font-weight: 700;
  transition:
    background-color 0.3s,
    transform 0.3s;
  &:hover {
    transform: translateY(-0.1em);
  }
  &:active {
    transform: $button-active-transform;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import "./components/UserInfo.scss";
@import "./components/ErrorPopup.scss";
@import "./components/LanguageSelector.scss";

body {
  margin: 0;
  font-family: $font-family;
  background-color: $primary-bg;
  color: $text-color;
}

// App light theme start
.App {
  &.light {
    .header {
      background-color: $primary-bg-light;
      color: $primary-bg;
      box-shadow: 0 2px 4px $box-shadow;
      .left-container {
        .top-logo {
          background-image: url("/Images/logo_dark_com.png");
        }
        .logout {
          button {
            background-color: $button-bg;
            color: $primary-bg;
            border: 0.1em solid #dadee2;
          }
        }
      }
      .right-container {
        .theme-toggle .toggle-checkbox:checked + .toggle-label {
          background-color: #fff;
          border: solid 0.1em #dadee2;
          .toggle-button {
            background-color: #8d888d;
          }
        }
        .hamburger span {
          background: #012734;
        }
        .side-menu {
          background-color: #fff;
          color: #000;
          button {
            color: #000;
          }
        }
      }
    } // Header end

    .homepage,
    .login,
    .loading,
    .footer,
    .about-us {
      background: $primary-bg-light;
      color: $primary-bg;
      font-weight: 600;
    }

    .bridge-selection {
      background-color: #fff;
      color: #012734;
      border: 0.1em solid #dadee2;
      h2 {
        color: #8d888d;
      }
      .buttons-container button {
        background: #eaf2ff;
        border: 0.1em solid #dadee2;
        box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
        color: #012734;
        span {
          color: #012734;
        }
        &:hover {
          background-color: lighten(#fff, 10%);
        }
      }
      .bridge-footer {
        .footer-logo {
          background-image: url("/Images/logo_dark_com.png") !important;
        }
      }
    }

    .login {
      .login-container {
        background-color: #fff;
        border: 0.1em solid #dadee2;
        .input-group {
          label {
            color: #8d888d;
          }
          input {
            background: rgba(239, 239, 239, 0.8);
            border: 0.1em solid #d2d6db;
            color: #012734;
            font-weight: 600;
            &::placeholder {
              color: #012734;
            }
            &:-webkit-autofill {
              background-color: unset !important;
              -webkit-box-shadow: unset !important;
              -webkit-text-fill-color: unset !important;
            }
          }
        }
        header.login-header {
          .back-button {
            background-image: url("/Images/back_icon_dark.png") !important;
          }
          h1 {
            color: #012734;
          }
        }
        h2 {
          color: #012734;
        }
        footer.login-footer {
          .footer-logo {
            background-image: url("/Images/logo_dark_com.png") !important;
          }
        }
      }
    }

    .bridge-iframe {
      background: #eaf2ff;
      .close-button {
        background-color: #f0f0f0;
        color: #101010;
        &:hover {
          background-color: #dcdcdc;
          color: #000;
        }
      }
    }

    .about-us {
      background: #eaf2ff;
      color: #012734;
      &__value-description,
      &__subtitle {
        color: #012734;
      }
      &__value {
        background: #fff;
        color: #012734;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        &:hover {
          transform: translateY(-5px);
        }
      }
    }

    .loading .spinner {
      border: 0.3em solid #8d888d;
      border-top: 0.3em solid #012734;
      border-radius: 50%;
      width: 3em;
      height: 3em;
      animation: spin 1s linear infinite;
    } // Loading end
  }
} // App light theme end

// App start
.App {
  // Header start
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em 0em;
    box-shadow: 0 2px 4px $box-shadow;
    width: 100%;
    background-color: $primary-bg;
    color: $text-color;
    text-align: center;
    user-select: none;

    h1 {
      margin: 0;
      font-size: $font-size-large;
    }

    p {
      margin: 0;
      font-size: $font-size-default;
    }
    // left-container start
    .left-container {
      display: flex;
      align-items: center;

      .top-logo {
        background-image: url("/Images/logo_com.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 11em;
        height: 2em;
        margin-left: 1em;
        cursor: pointer;
      }

      .logout {
        margin-left: 1em;

        button {
          @include button-styles;
          background-color: #1d1e24;
          color: #fff;
          border: 0.1em solid #45485a;
          font-size: 0.9em;
          padding: 0;
          margin: 0;
          min-width: 6em;
          min-height: 2.2em;
        }
      }
    } // left-container end

    // right-container start
    .right-container {
      display: flex;
      align-items: center;

      // Theme Toggle Start
      .theme-toggle {
        position: relative;
        width: auto;
        display: flex;
        justify-content: center;
        margin-right: 5em;

        .toggle-checkbox {
          display: none;

          &:checked + .toggle-label {
            background-color: #d2d6db;
            border: solid 0.1em #d2d6db;

            .toggle-button {
              background-color: #777a82;
            }

            .theme-icon {
              transform: translateX(2.2em);
            }
          }
        }

        .toggle-label {
          display: flex;
          align-items: center;
          width: 4em;
          height: 2em;
          background-color: #30323e;
          border: solid 0.1em #111216;
          border-radius: 2.5em;
          cursor: pointer;
          position: relative;
        }

        .toggle-button,
        .theme-icon {
          display: block;
          width: 1.2em;
          height: 1.2em;
          position: absolute;
          top: 0.35em;
          transition: transform 0.3s ease;
        }

        .toggle-button {
          background-color: #777a82;
          border-radius: 50%;
          right: 0.3em;
        }

        .theme-icon {
          left: 0.3em;
        }

        .toggle-checkbox:checked + .toggle-label .toggle-button {
          transform: translateX(-2.2em);
        }
      } // Theme Toggle End

      // Hamburger start
      .hamburger {
        position: absolute;
        top: 0.46em;
        right: 0.9em;
        width: 2em;
        height: 2em;
        cursor: pointer;
        z-index: 5; // Ensure it stays above the menu

        span {
          background: #fff;
          border-radius: 1em;
          display: block;
          height: 0.25em;
          width: 100%;
          margin: 0.45em 0;
          transition:
            transform 0.4s,
            background 0.4s;
        }

        &.open span:nth-child(1) {
          transform: rotate(45deg) translate(0.5em, 0.5em);
        }

        &.open span:nth-child(2) {
          opacity: 0;
        }

        &.open span:nth-child(3) {
          transform: rotate(-45deg) translate(0.5em, -0.5em);
        }
      } // Hamburger end

      // Side menu start
      .side-menu {
        position: fixed;
        top: 0;
        right: -25em;
        width: 10.5em;
        height: 100%;
        background-color: $secondary-bg;
        color: #fff;
        transition: right 0.4s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 4; // Ensure it's below the hamburger

        &.open {
          right: 0;
        }

        button {
          margin: 10px 0;
          font-size: 1.2em;
          cursor: pointer;
          user-select: none;
          background: none;
          border: none;
          color: #fff;

          &:nth-child(1):hover {
            color: #8d888d;
          }
          &:nth-child(2):hover {
            color: #f74b8d;
          }

          &:nth-child(3):hover {
            color: #019eff;
          }

          &:nth-child(4):hover {
            color: #fe9725;
          }
        }

        .side-menu-top {
          width: 90%;
          margin-top: 4em;
        }

        .side-menu-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          width: 100%;
          gap: 0.4em;
        }

        @media screen and (max-width: 620px) {
          .side-menu-content {
            button {
              margin: 0.5em 0;
            }
          }
        }
      } // Side menu end
    } // right-container end
  } // Header end

  // Footer start
  .footer {
    width: 100%;
    background-color: $primary-bg;
    color: $text-color;
    text-align: center;
    padding: 1.7em 0 0.6em 0em;
    box-shadow: 0 2px 4px $box-shadow;
    user-select: none;

    h1 {
      margin: 0;
      font-size: $font-size-large;
    }

    p {
      margin: 0;
      font-size: $font-size-default;
    }
  } // Footer end

  .homepage,
  .login,
  .loading,
  .about-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 7em);
    background: $primary-bg;
    text-align: center;
    user-select: none;
  }

  // back-button start
  .back-button {
    @include button-styles;
    padding: 0.5em 1em;
    background-color: $button-bg;
    color: $primary-bg;
    font-size: 1em;
    margin-bottom: 1em;

    &:hover {
      background-color: $button-hover-bg;
      color: $button-hover-color;
    }
  } // back-button end

  // bridge-iframe start
  .bridge-iframe {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 7em);
    background: #101010;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    align-content: center;
    iframe {
      width: 22em;
      height: 31em;
      border: solid 0.1em #45485a;
      border-radius: 1em;
    }

    .close-button {
      position: relative;
      top: 2em;
      left: 7em;
      width: 1.3em;
      height: 1.3em;
      border: 0.01em solid #6f6f6f;
      border-radius: 50%;
      cursor: pointer;
      background-color: #171717;
      color: lighten(#6f6f6f, 10%);
      font-size: 1.3em;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      transition:
        background-color 0.3s,
        color 0.3s;
      margin: 0;
      padding: 0;
      line-height: 1; /* Ensure the line-height does not affect centering */
      overflow: hidden;
      box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */

      &.overlay-visible {
        z-index: -1;
      }

      &:hover {
        background-color: lighten(#6f6f6f, 10%);
        font-weight: 600;
        color: lighten($primary-bg, 10%);
      }

      &:before {
        content: "\00D7"; /* Unicode character for '×' */
        font-size: 1em;
        font-weight: 100;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  } // bridge-iframe end

  // .bridge-selection start
  .bridge-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5em;
    background-color: #1d1e24;
    border-radius: 1em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    border: 0.1em solid #45485a;
    justify-content: center;
    max-width: 26em;
    width: 100%;

    .bridge-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 1em;

      h1 {
        margin: 0em 0em 0em 1.25em;
        font-size: 1.6em;
        font-weight: 700;
        flex-grow: 1;
        text-align: center;
      }

      .top-logo {
        width: 2em;
        height: 2em;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    h2 {
      margin: 0.1em;
      font-size: 1.3em;
      color: #8d888d;
    }

    .buttons-container {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      margin-top: 1em;

      button {
        background: #30323e;
        border: 0.1em solid #45485a;
        border-radius: 0.5em;
        padding: 2em 1em;
        width: 45%; // Fixed width to maintain size
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
        transition:
          background-color 0.3s,
          transform 0.3s;
        color: #101010;
        font-size: 1.2em;
        margin: 1em 0;
        cursor: pointer;
        font-weight: 700;
        text-align: center;
        white-space: nowrap; // Prevent text wrapping
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: lighten(#30323e, 10%);
          transform: translateY(-0.1em);
        }

        img {
          width: 3em;
          height: auto;
          margin-bottom: 1em;
        }

        span {
          font-size: 1.2em;
          font-weight: 600;
          color: #fff;
          display: block;
        }
      }
    }

    .bridge-footer {
      margin-top: 1em;
      width: 100%;

      .footer-logo {
        width: 9.5em;
        height: 2.5em;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
      }
    }
  } // .bridge-selection end

  // .login start
  .login {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .login-container {
      background-color: $secondary-bg;
      border: 0.1em solid #45485a;
      width: 100%;
      // max-width: max-content;
      max-width: 23em;
      min-width: 23em;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2em 2em;
      border-radius: 1em;
      text-align: center;

      header.login-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5em;

        .back-button {
          background: none;
          border: none;
          color: #fff;
          font-size: 0.5em;
          cursor: pointer;
          width: 2.5em;
          height: 2.5em;
          background-size: contain;
          background-repeat: no-repeat;
        }

        h1 {
          font-size: 1.5em;
          color: $text-color;
          flex-grow: 1;
          text-align: center;
          margin: 0;
        }

        .top-logo {
          width: 2em;
          height: 2em;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      h2 {
        font-size: 1.2em;
        color: $text-color;
        margin-bottom: 1.5em;
      }

      .input-group {
        width: 100%;
        margin-bottom: 1.5em;

        label {
          display: block;
          font-size: 0.8em;
          color: #777a82;
          font-weight: 600;
          margin-bottom: 0.5em;
          text-align: left;
        }

        .input-wrapper {
          position: relative;

          .info-icon {
            position: absolute;
            right: 1em;
            top: 50%;
            transform: translateY(-50%);
            width: 1.6em;
            height: auto;
          }
        }

        input {
          width: 100%;
          padding: 0.9em;
          font-size: 1em;
          border: 0.1em solid #45485a;
          border-radius: 0.5em;
          background: #30323e;
          color: $text-color;
          min-width: 12em;
          max-width: 21em;

          &::placeholder {
            color: #ffffff;
          }

          &:-webkit-autofill {
            background-color: rgba(48, 50, 62, 0.9) !important;
            -webkit-box-shadow: 0 0 0 30px rgba(48, 50, 62, 0.9) inset !important;
            -webkit-text-fill-color: #fff !important;
          }
        }
      }

      button {
        @include button-styles;
        padding: 0.782em 2em;
        margin: 0.5em 0;
        background-color: $button-bg;
        color: $primary-bg;
        font-size: 1.2em;
        width: 100%;
        // min-width: 12em;
        max-width: 21em;

        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }

      .error-container {
        height: 1.5em;

        .error {
          color: $error-color;
          font-size: $font-size-small;
          font-weight: 600;
          letter-spacing: 0.05em;
        }
      }

      footer.login-footer {
        margin-top: 1.5em;
        width: 100%;
        display: flex;
        justify-content: center;

        .footer-logo {
          width: 10.5em;
          height: 2.5em;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  } // .login end

  // About Us start
  .about-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 7em);
    background: #101010;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    color: #ffffff;
    padding: 0 2em;
    font-weight: 600;

    &__intro {
      margin-bottom: 2em;
    }

    &__title {
      font-size: 2.5em;
      margin-bottom: 0.5em;
      margin-top: 0;
    }

    &__subtitle {
      font-size: 1.2em;
      color: #b0b0b0;
      margin-bottom: 0;
    }

    &__section-title {
      font-size: 2em;
      margin-bottom: 1em;
      margin-top: 0;
    }

    &__values-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__value {
      background: #1c1c1c;
      border-radius: 10px;
      padding: 1.5em;
      margin: 0.5em;
      width: 250px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: translateY(-5px);
      }
    }

    &__value-title {
      font-size: 1.5em;
      margin-bottom: 0.5em;
      margin-top: 0;
    }

    &__value-description {
      font-size: 1em;
      color: #d0d0d0;
      margin-bottom: 0;
    }
  }
  // About Us end

  // Loading start
  .loading .spinner {
    border: 0.3em solid $light-text-color;
    border-top: 0.3em solid $text-color;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite;
  } // Loading end
} // App end

//                              Media Queries
@media screen and (max-width: 480px) {
  .App {
    &.light {
      .header {
        margin-bottom: -0.1em;
        box-shadow: none;
      }
      .footer {
        padding: 1.5em 0 1.2em 0em;
        margin-top: -0.1em;
        box-shadow: none;
      }
    }
    .login {
      .login-container {
        width: 70%;
        padding: 1em 2em;
        max-width: 20em;
        min-width: 14em;
        h2 {
          font-size: 0.9em;
          margin-bottom: 2em;
        }
        footer.login-footer {
          margin-top: 0.5em;
        }
        .input-group {
          button,
          input {
            font-size: 1em;
            max-width: 15em;
          }
        }
      }
    }

    .logout {
      top: 0.8em;
      left: 0.5em;

      button {
        font-size: 0.8em;
      }
    }

    .footer {
      padding: 1.5em 0 1em 0em;
      p {
        font-size: 0.9em;
      }
    }

    .header {
      h1 {
        font-size: 1.2em;
      }
      .left-container {
        .top-logo {
          height: 1.6em;
          width: 9em;
        }
        .logout {
          button {
            font-size: 0.7em;
          }
        }
      }
      .right-container {
        .side-menu {
          width: 8.2em;
          button {
            font-size: 0.92em;
          }
        }
        .theme-toggle {
          margin-right: 4em;
        }
      }
    }

    .homepage {
      .bridge-selection {
        padding: 2.5em 1em;
        max-width: 20em;
        .bridge-header {
          padding-bottom: 2em;
        }
        .buttons-container {
          margin-top: 0.5em;
          margin-bottom: 2em;
          button {
            img {
              width: 3em;
              height: auto;
              margin-bottom: 1em;
            }
            span {
              font-size: 1.1em;
            }
          }
        }

        h1 {
          font-size: 1.5em;
        }

        button {
          width: 44%;
          padding: 1.8em 0.5em;
          font-size: 1em;
        }
      }
    }

    .about-us {
      padding: 1em;

      &__title {
        font-size: 2em;
      }

      &__subtitle {
        font-size: 1em;
      }

      &__section-title {
        font-size: 1.5em;
      }

      &__values-list {
        flex-direction: column;
        align-items: center;
      }

      &__value {
        width: 80%;
        margin: 0.5em 0;
      }
    }
  } // App end
} // Media 480px end

@media screen and (max-width: 400px) {
  .App {
    .login {
      .login-container {
        button {
          max-width: 14em;
        }
        .input-group {
          input {
            max-width: 14em;
            min-width: 14em;
          }
        }
      }
    }
  }
} // Media 400px end

@media screen and (max-width: 374px) {
  .App {
    .homepage {
      .bridge-selection {
        max-width: 17em;
      }
    }
    .login {
      .login-container {
        button {
          max-width: 12em;
        }
        .input-group {
          input {
            max-width: 12em;
            min-width: 12em;
          }
        }
      }
    }
    .header {
      h1 {
        margin-left: 2em;

        &[data="false"] {
          margin-left: 0;
        }
      }
    }
  } // App end
} // Media 374px end

@media screen and (max-width: 620px) {
  .bridge-selection {
    .buttons-container {
      button {
        width: 100%; // Adjust for smaller screens
      }
    }
  }
}
