.language-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-family;
    min-width: 7.5em; // Changed from 120px to 7.5em
    margin-right: 2em;
}

.selected-language {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.35em;
    border-radius: 1.563em; // Changed from 25px to 1.563em
    background-color: $secondary-bg;
    color: $text-color;
    transition: all 0.3s ease;
    width: 100%;
    
    &:hover {
        background-color: lighten($secondary-bg, 5%);
        transform: translateY(-0.063em); // Changed from -1px to -0.063em
    }
    
    img {
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-right: 0.8em;
        box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1); // Changed from 2px 4px
    }
  
    span {
        margin-right: 0.8em;
        font-size: $font-size-default;
        font-weight: 500;
    }
  
    .arrow {
        font-size: 0.8em;
        transition: transform 0.3s ease;
        margin-left: auto;
    }
  
    &.open .arrow {
        transform: rotate(180deg);
    }
}

.language-options {
    position: absolute;
    top: calc(100% + 0.5em);
    right: 0;
    background-color: $secondary-bg;
    border: 0.1em solid rgba(69, 72, 90, 0.5);
    border-radius: 1em;
    box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.2); // Changed from 4px 16px
    margin-top: 0.5em;
    width: 100%;
    max-height: 12em; // Increased from 12em to allow more languages to be visible
    overflow-y: auto; // Ensure vertical scrollbar is enabled
    overflow-x: hidden;
    padding: 0.5em 0;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.4) rgba(0, 0, 0, 0.2);
    z-index: 10;
    animation: slideDown 0.2s ease-out;
    
    &::-webkit-scrollbar {
        width: 0.4em; // Increased scrollbar width for better visibility
        background-color: rgba(0, 0, 0, 0.2);
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.4); // Brighter scrollbar thumb
        border-radius: 1em; // Adjusted for better appearance
        
        &:hover {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
    
    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 1em;
    }
  
    div {
        display: flex;
        align-items: center;
        padding: 0.7em 0.2em;
        margin: 0.2em 0.5em;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 0.5em;
  
        img {
            width: 1.3em;
            height: 1.3em;
            border-radius: 50%;
            margin-right: 1em;
            box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1); // Changed from 2px 4px
        }
  
        span {
            font-size: $font-size-default;
            color: $text-color;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
  
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            transform: none;
            margin-right: 0.5em;
        }
    }
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-0.625em); // Changed from -10px
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.App.light {
  .language-selector {
    .selected-language {
      background-color: #fff;
      color: #012734;

      &:hover {
        background-color: darken($primary-bg-light, 5%);
      }

      img {
        box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.2);
      }
    }

    .language-options {
      background-color: $primary-bg-light;
      border: 0.1em solid rgba(69, 72, 90, 0.3);
      scrollbar-color: rgba(0, 0, 0, 0.4) rgba(255, 255, 255, 0.2);

      &::-webkit-scrollbar {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      div {
        background-color: $primary-bg-light;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
          transform: translateX(0.2em);
        }

        img {
          box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.2);
        }

        span{
            color: #012734;
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .side-menu {
    .side-menu {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .language-selector {
      width: 90%;
      margin: 0em auto;
      min-width: auto;

      .selected-language {
        justify-content: center;
        padding: 0.5em;
        
        img {
          width: 1.2em;
          height: 1.2em;
          margin-right: 0.5em;
        }
        
        span {
          font-size: 0.9em;
        }
      }

      .language-options {
        width: 100%;
        max-height: 15em; // Adjusted max-height for smaller screens
        
        div {
          padding: 0.6em;
          
          img {
            width: 1.2em;
            height: 1.2em;
          }
          
          span {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}