// src/components/ErrorPopup.scss
.error-popup {
    position: fixed;
    top: 4.5em;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8);
    color: #fff;
    padding: 0.1em 1em;
    border-radius: 0.5em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
    z-index: 1000;
    text-align: center;
    width: 100%;
    max-width: 22em;
    animation: alertfadein 0.7s linear forwards;
  
    &.fade-out {
      animation: alertfadeout 0.7s linear forwards;
    }
  
    @media (max-width: 480px) {
      max-width: 15em;
    }
  }
  
  @keyframes alertfadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes alertfadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  