// src/index.scss

/* Global scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #101010; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #333; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
  border: 3px solid #101010; /* Padding around the thumb to make it look inset */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color when hovering over the scrollbar thumb */
}

body {
  // margin: 0;
  font-family: 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-size: 10px;

  /* Apply these styles to ensure the custom scrollbar is used */
  scrollbar-color: #333 #101010; /* For Firefox */
  scrollbar-width: thin; /* For Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
